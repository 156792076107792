

/* GENERICS
--------------------------------------------------------------------------------------------*/
@import 'components/generics/anchors';
@import 'components/generics/buttons';
@import 'components/generics/form';
@import 'components/generics/hamburger';
@import 'components/generics/hr';
@import 'components/generics/list-search';
@import 'components/generics/maps';
@import 'components/generics/slick-slider';
@import 'components/generics/svg';


/* PARTS
--------------------------------------------------------------------------------------------*/
@import 'components/parts/ac_content';
@import 'components/parts/ac_data-s-amount';
@import 'components/parts/ac_backdrop';
@import 'components/parts/ac_section_divider';
@import 'components/parts/ac_heading';
@import 'components/parts/ac_socials';


/* FOOTERS
--------------------------------------------------------------------------------------------*/
@import 'components/footers/ac_f_footer_1/footer_1_1';
//@import 'components/footers/ac_f_footer_2/footer_2_2';


/* MENUS
--------------------------------------------------------------------------------------------*/

// horizontal menus
//@import 'components/menus/ac_m_menu_1/menu_1_1';
@import 'components/menus/ac_m_menu_2/menu_2_1';
//@import 'components/menus/ac_m_menu_3/menu_3_1';
//@import 'components/menus/ac_m_menu_4/menu_4_1';
//@import 'components/menus/ac_m_menu_5/menu_5_1';

// vertical menus
//@import 'components/menus/ac_m_menu_101/menu_101_1';



/* SECTIONS
--------------------------------------------------------------------------------------------*/

@import 'components/sections/ac_s_brands/brands_1';

@import 'components/sections/ac_s_partners/partners_1';

@import 'components/sections/ac_s_team/team_1';

@import 'components/sections/ac_s_cta/cta_2';
@import 'components/sections/ac_s_cta_text-button/cta_text-button_2';
@import 'components/sections/ac_s_cta_text-image/cta_text-image_1';
@import 'components/sections/ac_s_cta_text-socials/cta_text-socials_3';

@import 'components/sections/ac_s_hero/hero_2';
@import 'components/sections/ac_s_hero_alt/hero_alt_1';

@import "components/sections/ac_s_image/image_1";
@import "components/sections/ac_s_image-bg-fixed/image-bg-fixed_1";
@import "components/sections/ac_s_image-gallery_full-screen/image-gallery_full-screen_1";
@import "components/sections/ac_s_image-gallery_grid/image-gallery_grid_2";
@import "components/sections/ac_s_image-gallery/image-gallery_1";

@import 'components/sections/ac_s_maps/maps_1';

@import 'components/sections/ac_s_news/news_3';

@import 'components/sections/ac_s_references/references_2';
@import 'components/sections/ac_s_references-grid/references-grid_1';

@import 'components/sections/ac_s_services-grid/services-grid_1';
@import 'components/sections/ac_s_services/services_2';

@import 'components/sections/ac_s_toptasks/toptasks_1';